import axios from "axios";
import {IDevice, IDeviceModel, INote} from "../lib/types/types";
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {UtilService} from "./utilService";
import {UtilService as NewUtilService} from '@xal3xfx/react-utils'

export class DeviceService{
    service = 'devices/';

    async getAllDevices(){
        return new Promise<IDevice[]>((resolve, reject) => {
            axios.get(this.service + "true").then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllDevicesDropdown(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IDevice[]>(this.service + "true").then((resp: any) => {
                resolve(NewUtilService.generateDropdownOptionsFromData(resp.data, "deviceId", "deviceNumber"));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getDeviceById(deviceId: number){
        return new Promise<IDevice>((resolve, reject) => {
            axios.get<IDevice>(this.service  + "getById/" +deviceId + "/true").then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async saveDevice(device: Partial<IDevice>){
        return new Promise<IDevice>((resolve, reject) => {
            if(!device.simCardId) delete device.simCardId;
            axios.post(this.service ,device).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async addDevicesBulk(imeis: number[], deviceModelId?: number) {
        return new Promise<{[imei:number] : boolean}>((resolve, reject) => {
            const url = deviceModelId ? `addmultiple/${deviceModelId}` : "addmultiple";
            axios.post(this.service + url, imeis).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteDevice(deviceId : number){
        return new Promise<IDevice>((resolve, reject) => {
            axios.delete(this.service + deviceId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getDeviceModelsCombo(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IDevice[]>(this.service + 'models').then((resp: any) => {
                const data : IDropdownOption[] = resp.data.map((el: IDevice) => {
                    return {description: `${el.deviceType.deviceTypeName} - ${el.deviceManufacturerName} - ${el.deviceModelName}`, id: el.deviceModelId, key: el.deviceModelId}
                })

                resolve(data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createDeviceModel(deviceModel: Partial<IDeviceModel>){
        return new Promise<IDeviceModel>((resolve, reject) => {
            axios.post(this.service + 'model', deviceModel).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createDeviceType(deviceType: {name: string}){
        return new Promise<IDeviceModel>((resolve, reject) => {
            axios.post(this.service + 'type', {typeName: deviceType.name}).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getDeviceTypesDropdown(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get(this.service + 'type').then((resp: any) => {
                resolve(UtilService.formatDropdownDataNewForm(resp.data.options));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getFreeDevices(){
        return new Promise<IDevice[]>((resolve, reject) => {
            axios.get<IDevice[]>(this.service + 'free/true').then((resp: any) => {
                resolve(resp.data.filter((el: IDevice) => el.activeTable !== ""));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getFreeDevicesDropdown(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IDropdownOption[]>(this.service + 'free/true').then((resp: any) => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data.filter((el: IDevice) => el.activeTable !== ""), 'deviceId', 'deviceNumber'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async addNote(deviceId: number, note: string) {
        return new Promise<INote[]>((resolve, reject) => {
            axios.post(this.service + `${deviceId}/deviceNote`, {note}).then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            })
        })
    }
}
