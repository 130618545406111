import {
    ClientFirmVehicleSummary,
    CreateClientFirm,
    IClientFirm,
    ICreateInvoiceDetails, ICreateInvoiceNumbers,
    IEmailContact, IEmployee,
    IFirmEmail,
    IFirmPhone, IInvoiceDetail, IInvoiceNumbers,
    IPhoneContact,
    IRawDropdownOption,
    ITariff,
    ITariffType, IUpdateInvoiceNumbers,
    IVehicle,
    VehicleInvoiceGroupTable
} from "../lib/types/types";
import axios from "axios";
import {UtilService} from "./utilService";
import {UtilService as NewUtilService} from '@xal3xfx/react-utils'
import {IDropdownOption} from "@xal3xfx/react-utils/dist/util-service";
import {ClientFirmDto} from "./backend-api";


export class ClientFirmService {
    service = "clientfirms/"

    async getAllClientFirms(orderById?: boolean){
        return new Promise<IClientFirm[]>((resolve, reject) => {
            axios.get<IClientFirm[]>(this.service).then((resp) => {
                if(orderById){
                    resolve(resp.data.sort((a,b) => b.clientFirmId - a.clientFirmId))
                }else{
                    resolve(resp.data);
                }
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async saveClientFirm(clientFirm: CreateClientFirm){
        return new Promise<IClientFirm>((resolve, reject) => {
            axios.post(this.service ,clientFirm).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    async getFirmsCombo() {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<{options: IRawDropdownOption[]}>(this.service + "dhtmlxcombo")
            .then(response => {
                resolve(UtilService.formatDropdownDataNewForm(response.data.options));
            })
            .catch(reject)
        })
    }

    async getClientFirmsDropdown(useFirmNameForValue: boolean) {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IClientFirm[]>(this.service)
                .then(response => {
                    resolve(NewUtilService.generateDropdownOptionsFromData<IClientFirm>(response.data, useFirmNameForValue ? 'clientFirmName' : 'clientFirmId', 'clientFirmName'));
                })
                .catch(reject)
        })
    }

    async getClientFirmById(id: number) {
        return new Promise<IClientFirm>((resolve, reject) => {
            axios.get<IClientFirm>(this.service + id)
                .then(response => {
                    resolve(response.data);
                })
                .catch(reject)
        })
    }

    // async getEmployeeById(employeeId: number){
    //     return new Promise<IClientFirm>((resolve, reject) => {
    //         axios.get(this.service  + employeeId).then((resp: any) => {
    //             resolve(resp.data);
    //         }).catch(err => {
    //             console.error(err);
    //             reject(err);
    //         });
    //     });
    // }

    async deleteClientFirm(clientFirmId : number){
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(this.service + clientFirmId).then((resp: any) => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getEmployees(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get('employee').then((resp: any) => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data, 'employeeName', 'employeeName'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllDistributors(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IEmployee[]>('employee').then((resp: any) => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data.filter((el: IEmployee) => el.employeePositionId === 1), 'employeeName', 'employeeName'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllTraders(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<IEmployee[]>('employee').then((resp: any) => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data.filter((el: IEmployee) => el.employeePositionId === 2), 'employeeName', 'employeeName'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllVehicles(){
        return new Promise<[any]>((resolve, reject) => {
            axios.get('vehicles').then((resp:any) => {
                resolve(UtilService.generateDropdownOptionsFromData(resp.data, 'vehicleId','vehicleLpn'))
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        })
    }

    async getClientFirmVehicles(clientFirmId: number){
        return new Promise<IVehicle[]>((resolve,reject) => {
            axios.get<IVehicle[]>(this.service + clientFirmId + '/vehicles').then(resp => {
                resolve(resp.data)
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        })
    }

    async getClientFirmVehiclesDropdown(clientFirmId: number){
        return new Promise<IDropdownOption[]>((resolve,reject) => {
            axios.get<IVehicle[]>(this.service + clientFirmId + '/vehicles').then(resp => {
                resolve(NewUtilService.generateDropdownOptionsFromData<IVehicle>(resp.data, "vehicleId", 'vehicleLpn'))
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        })
    }


    async getAllTariffsForFirm(clientFirmId: number){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.get<ITariff[]>(this.service + clientFirmId +'/tarifs').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getAllTariffTypes(){
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get<ITariffType[]>(this.service +'tarifs/types').then(resp => {
                resolve(UtilService.generateDropdownOptionsNewFormatFromData(resp.data, 'tarifTypeId', 'tarifTypeName'));
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createTariff(clientFirmId: number, tariff: ITariff){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.post<ITariff[]>(this.service + clientFirmId + '/tarifs', tariff).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteTariff(clientFirmId: number, tariffId: number){
        return new Promise<ITariff[]>((resolve, reject) => {
            axios.delete<ITariff[]>(this.service + clientFirmId + '/tarifs/' + tariffId).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getFirmPhones(clientFirmId: number) {
        return new Promise<IFirmPhone[]>((resolve, reject) => {
            axios.get<IFirmPhone[]>(this.service + clientFirmId + '/phones').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteFirmPhone(firmId: number, phone: IPhoneContact) {
        return new Promise<IFirmPhone[]>((resolve, reject) => {
            axios.delete<IFirmPhone[]>(this.service + firmId + '/phones', {data: {...phone}}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createPhoneNumber(firmId: number, phone: IPhoneContact) {
        return new Promise<IFirmPhone[]>((resolve, reject) => {
        axios.post<IFirmPhone[]>(this.service + firmId + '/phones', phone).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async updatePhoneNumber(firmId: number, phone: IPhoneContact) {
        return new Promise<IFirmPhone[]>((resolve, reject) => {
        axios.put<IFirmPhone[]>(this.service + firmId + '/phones', phone).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async createEmail(firmId: number, phone: IEmailContact) {
        return new Promise<IFirmEmail[]>((resolve, reject) => {
        axios.post<IFirmEmail[]>(this.service + firmId + '/emails', phone).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async updateEmail(firmId: number, phone: IEmailContact) {
        return new Promise<IFirmEmail[]>((resolve, reject) => {
        axios.put<IFirmEmail[]>(this.service + firmId + '/emails', phone).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getFirmMails(clientFirmId: number) {
        return new Promise<IFirmEmail[]>((resolve, reject) => {
            axios.get<IFirmEmail[]>(this.service + clientFirmId + '/emails').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async deleteFirmMail(firmId: number, mail: IFirmEmail) {
        return new Promise<IFirmEmail[]>((resolve, reject) => {
            axios.delete<IFirmEmail[]>(this.service + firmId + '/emails', {data: {...mail}}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getFirmVehicles(firmId: number) {
        return new Promise<IVehicle[]>((resolve, reject) => {
            axios.get<IVehicle[]>(this.service + firmId + '/vehicles').then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async addFirmVehicles(firmId: number, vehiclesToAddIds: number[]) {
        return new Promise<IVehicle[]>((resolve, reject) => {
            //TODO: Wait for Niki to create method for bulk create
            axios.post(this.service + firmId + '/vehicles', vehiclesToAddIds).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async removeFirmVehicles(firmId: number, vehiclesToRemoveIds: number[]) {
        return new Promise<IVehicle[]>((resolve, reject) => {
            //TODO: Wait for Niki to create method for bulk create
            axios.delete(this.service + firmId + '/vehicles', {data: vehiclesToRemoveIds}).then(resp => {
                resolve(resp.data);
            }).catch(err => {
                console.error(err);
                reject(err);
            });
        });
    }

    async getInvoiceDetailsDropdown(firmId: number) {
        return new Promise<IDropdownOption[]>((resolve, reject) => {
            axios.get(this.service + firmId + "/invoicedetails")
                .then(resp => {
                    resolve(resp.data.map((el:any)=> {
                        return {
                            key: el.invoiceDetailsId,
                            description: el.invoiceDetailsName,
                            id: el.invoiceDetailsId
                        }
                    }))
                }).catch(reject)
        })
    }

    async getInvoiceDetails(firmId: number) {
        return new Promise<ICreateInvoiceDetails[]>((resolve, reject) => {
            axios.get(this.service + firmId + "/invoicedetails")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getVehiclesForInvoiceGroupAndClient(clientFirmId: number, invoiceGroup: number) {
        return new Promise<VehicleInvoiceGroupTable[]>((resolve, reject) => {
            axios.get<VehicleInvoiceGroupTable[]>(this.service + clientFirmId + "/invoicegroup/" + invoiceGroup + "/vehicles")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async getAllVehiclesForGroupWithoutInvoiceGroup(clientFirmId: number) {
        return new Promise<VehicleInvoiceGroupTable[]>((resolve, reject) => {
            axios.get<VehicleInvoiceGroupTable[]>(this.service + clientFirmId + "/vehicles/noinvoicegroup")
                .then(resp => {
                    resolve(resp.data);
                }).catch(reject)
        })
    }

    async createInvoiceDetails(data:ICreateInvoiceDetails, clientFirmId: number) {
        return new Promise<IInvoiceDetail[]>((resolve, reject) => {
            axios.post<IInvoiceDetail[]>(this.service + clientFirmId + "/invoicedetails", data)
                .then(resp => {
                    resolve(resp.data)
                }).catch(reject)
        })
    }

    async updateInvoiceDetails(data:ICreateInvoiceDetails, clientFirmId: number) {
        if(!data.invoiceDetailsId) return;
        return new Promise<IInvoiceDetail[]>((resolve, reject) => {
            axios.put<IInvoiceDetail[]>(this.service + clientFirmId + "/invoicedetails", data)
                .then(resp => {
                    resolve(resp.data)
                }).catch(reject)
        })
    }

    async deleteInvoiceDetails(invoiceDetailsId: number, clientFirmId: number) {
        return new Promise<boolean>((resolve, reject) => {
            axios.delete(this.service + clientFirmId + "/invoicedetails/" + invoiceDetailsId)
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async getInvoiceNumbers( clientFirmId: number) {
        return new Promise<IInvoiceNumbers[]>((resolve, reject) => {
            const params = new URLSearchParams();
            params.append('clientFirmId', clientFirmId.toString());
            axios.get<IInvoiceNumbers[]>(this.service + "currentInvoiceNumber", {params, headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }} )
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async createInvoiceNumbers( data: ICreateInvoiceNumbers) {
        return new Promise<IInvoiceNumbers[]>((resolve, reject) => {
            axios.post<IInvoiceNumbers[]>(this.service + "currentInvoiceNumber", data )
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async updateInvoiceNumbers( data: IUpdateInvoiceNumbers) {
        return new Promise<IInvoiceNumbers[]>((resolve, reject) => {
            axios.put<IInvoiceNumbers[]>(this.service + "currentInvoiceNumber", data )
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async deleteInvoiceNumbers( clientFirmId: number, currentInvoiceNumberId: number) {
        return new Promise<IInvoiceNumbers[]>((resolve, reject) => {
            // const params = new URLSearchParams();
            // params.append('clientFirmId', clientFirmId.toString());
            // params.append('currentInvoiceNumberId', currentInvoiceNumberId.toString());
            axios.delete<IInvoiceNumbers[]>(this.service + "currentInvoiceNumber", {data: {clientFirmId: clientFirmId, currentInvoiceNumberId: currentInvoiceNumberId}}  )
                .then(resp => resolve(resp.data))
                .catch(reject)
        })
    }

    async getVehiclesSummary(clientFirmId: number) {
        return new Promise<ClientFirmVehicleSummary>((resolve, reject) => {
            axios.get<ClientFirmVehicleSummary>(this.service + clientFirmId + "/vehicleSummary")
                .then(resp => {
                    resolve(resp.data)
                }).catch(reject)
        })
    }

    async changeFirmStatus(clientFirmId: number, newStatus: number) {
        return new Promise<ClientFirmDto>((resolve, reject) => {
            axios.post<ClientFirmDto>(this.service + "status", {clientFirmId, clientFirmStatus: newStatus})
                .then(resp => {
                    if(resp.data) resolve(resp.data);
                    else reject(null);
                }).catch(reject)
        })
    }
}
