import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';

interface Props {
    invoicePdf: string
}

const InvoicePreview : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    return <>
        <object data={props.invoicePdf} type="application/pdf" width="100%" height="100%" style={{height: "2000px"}}>
            <p>Could not load PDF!</p>
        </object>
    </>
};

export default InvoicePreview
