import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useState, useRef} from 'react';
import {ReactiveTable} from "react-ive-tables";
import {InvoiceGroup} from "../../../../lib/types/types";
import {InvoiceService} from "../../../../services/invoiceService";

interface Props {
    clientFirmId: number;
    onSelect: (group: InvoiceGroup) => void;
    refreshTable?: number;
}

const FirmInvoiceGroups : React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const didMountRef = useRef(false);
    const invoiceService = new InvoiceService();

    const [invoiceGroupsForTable, setInvoiceGroupsForTable] = useState<InvoiceGroup[]>();
    const [selectedInvoiceGroup, setSelectedInvoiceGroup] = useState<InvoiceGroup>();

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if(!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    useEffect(() => {
        if(props.refreshTable) {
            setInvoiceGroupsForTable(undefined);
            setTimeout(() => loadData(), 10);
        }
    }, [props.refreshTable])

    const loadData = () => {
        invoiceService.getInvoiceGroupsForClientFirm(props.clientFirmId).then(setInvoiceGroupsForTable);
    }

    const getColumnTemplate = () => {
        return {
            invoiceCyrcle: (rowData: InvoiceGroup) => rowData.invoiceCyrcle === 0 ? 'Предходен' : 'Текущ'
        }
    }

    const handleSelection = (value: InvoiceGroup) => {
        setSelectedInvoiceGroup(value);
        props.onSelect(value);
    }

    return <>
        <ReactiveTable
            data={invoiceGroupsForTable}
            columnOrder={["invoiceGroupName", "invoiceDetailsName", "vehiclesCount", "invoiceGroupDurationInMonths", "invoiceCyrcle"]}
            ignoreFilters={['operations']}
            setSelected={handleSelection}
            columnTemplate={getColumnTemplate()}
            columnStyle={{operations: {body: {}, header: {width: '100px'}}}}
            selectionKey={'invoiceGroupId'}
            selectionMode={"single"}
        />
    </>
};

export default FirmInvoiceGroups
