import {useIntl} from 'react-intl';
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {Editor} from "primereact/editor";
import {InputText} from "primereact/inputtext";
import {Fieldset} from "primereact/fieldset";
import useSWR, {mutate} from "swr";
import {IDropdownOption, IEmployee, InternalTaskStatus} from "../../lib/types/types";
import {Chip} from "primereact/chip";
import {Button} from "primereact/button";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {Api, InternalTaskDto} from "../../services/backend-api";
import {Calendar} from "primereact/calendar";
import {OverlayPanel} from "primereact/overlaypanel";
import {UtilService} from "../../services/utilService";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import moment from "moment";
import {InternalTaskService} from "../../services/internalTaskService";
import {useToast} from "../../lib/hooks/useToast";
import InternalTaskComments from "./InternalTaskComments";
import {useUserInfo} from "../../lib/hooks/useUserInfo";
// import { Buffer } from "buffer";
//@ts-ignore
// import * as Zlib from "react-zlib-js"

interface Props {
    internalTaskId?: number
    closeDialog: () => void
}

const InternalTask: React.FC<Props> = props => {
    const {formatMessage: f} = useIntl();
    const {showToast} = useToast();
    const {theme} = useUserInfo();

    const [showTitleEditor, setShowTitleEditor] = useState(false);
    const [title, setTitle] = useState<string>("Заглавие");
    const [description, setDescription] = useState<any>("");
    const [status, setStatus] = useState<number>();
    const [dueDate, setDueDate] = useState<Date | undefined | null>(new Date());
    const [statusOptions, setStatusOptions] = useState<IDropdownOption[]>();
    const [assignees, setAssignees] = useState<number[]>([]);
    const [userOptions, setUserOptions] = useState<IDropdownOption[]>()
    const [createdAt, setCreatedAt] = useState<string>();
    const [selectedTaskId, setSelectedTaskId] = useState<number | undefined>(props.internalTaskId);
    const [openedOn, setOpenedOn] = useState<string>();
    const [createdBy, setCreatedBy] = useState<number>();

    const api = new Api();

    const internalTaskService = new InternalTaskService();

    const internalTaskStatusesKey = `/internaltasks/statuses`;
    const {data: internalTaskStatuses, isLoading, error} = useSWR<InternalTaskStatus[], Error>(internalTaskStatusesKey);

    const internalTaskKey = `/internaltasks/${selectedTaskId}`;
    const {data: internalTask} = useSWR<InternalTaskDto, Error>(selectedTaskId ? internalTaskKey : null);

    const employeesKey = `/employee/`;
    const {data: employees} = useSWR<IEmployee[], Error>(employeesKey);

    const didMountRef = useRef(false);
    const op = useRef<any>(null);
    const multiselectRef = useRef<any>(null);

    const statusColorMap = {
        0: "var(--cyan-600)",
        1: "var(--green-600)",
        2: "var(--blue-600)",
        3: "var(--yellow-600)",
        4: "var(--red-600)"
    }

    useEffect(() => {
        if (!didMountRef.current) {
            didMountRef.current = true;
        }
    }, []);

    // useEffect(() => {
    //     if (description) {
    //         const imgTagRegex = /<img[^>]+src="data:image\/[^;]+;base64,([^"]+)"/;
    //         const base64ImageMatch = description.match(imgTagRegex);
    //         if (base64ImageMatch) {
    //             const base64Image = base64ImageMatch[1];
    //
    //             const binaryData = Buffer.from(base64Image, 'base64');
    //
    //             // Step 2: Compress using zlib
    //             const compressedData = Zlib.gzipSync(binaryData);
    //
    //             // Step 3: Convert compressed data to Base64 string
    //             const compressedBase64 = Buffer.from(compressedData).toString('base64');
    //
    //             console.log(base64Image.length)
    //             console.log(compressedBase64.length);
    //
    //         } else {
    //             console.log("No Base64 image found in the img tag.");
    //         }
    //     }
    // }, [description])

    useEffect(() => {
        if (!internalTask) return;
        //Remove all black colors from the description if the selected theme is dark mode:
        let descFormatted = internalTask.description;
        if(theme === "dark") {
            descFormatted = descFormatted?.replaceAll("color: black", "color: white");
            descFormatted = descFormatted?.replaceAll("color: rgb(51, 51, 51)", "color: white");
        }
        setTitle(internalTask.tittle || "");
        setDescription(descFormatted);
        setStatus(internalTask.status);
        setDueDate(moment(internalTask.dueDateTime).toDate());
        setCreatedAt(moment(internalTask.dateTime).format("DD-MM-yyyy"));
        setAssignees(internalTask.assignedEmployees?.map(el => el.employeeId!) || [])
        setOpenedOn(moment(internalTask.openDateTime).format("DD-MM-yyyy hh:mm:ss"));
        setCreatedBy(internalTask.createdByEmployeeId);
    }, [internalTask])

    useEffect(() => {
        if (!employees) return;
        setUserOptions(UtilService.generateDropdownOptionsNewFormatFromData(employees, "employeeId", "employeeName"));
    }, [employees])

    useEffect(() => {
        setStatusOptions(internalTaskStatuses?.map(el => {
            return {key: el.id, value: el.id, label: el.value}
        }))
    }, [internalTaskStatuses]);


    const handleStatusChange = (e: DropdownChangeEvent) => {
        setStatus(e.value);
        api.internaltasks.statusesCreate({internalTaskId: internalTask?.internalTaskId, statusId: e.value})
            .then(resp => {
                if (resp) {
                    showToast("success", f({id: "done"}), f({id: "successfullyChangedStatus"}));
                }
            })
    }

    const handleAssigneeChange = (e: MultiSelectChangeEvent) => {
        //If creating a new task
        if (!internalTask) {
            setAssignees(e.value);
            return;
        }
        //If editing an existing task
        const currentAssignees = [...assignees];
        //If we are adding a new employee to the assignees
        if (currentAssignees.every(assignee => e.value.includes(assignee))) {
            //Find the newly selected employee
            const newAssignee = e.value.find((assignee: number) => !currentAssignees.includes(assignee));
            //If found - send request to assign to the task
            if (!newAssignee || !internalTask || !internalTask.internalTaskId) return;
            api.internaltasks.assignCreate({employeeId: newAssignee, internalTaskId: internalTask?.internalTaskId!})
                .then(resp => {
                    if (resp) setAssignees(e.value);
                })
        } else {
            let assigneeToRemove = currentAssignees.find(assignee => !e.value.includes(assignee));
            //If assigneeToRemove is undefined and the e.value is empty - we should remove the last assignee
            if (!assigneeToRemove && e.value.length === 0) {
                assigneeToRemove = currentAssignees[0];
            }
            if (!assigneeToRemove) return;
            api.internaltasks.unassignCreate({
                employeeId: assigneeToRemove,
                internalTaskId: internalTask?.internalTaskId!
            })
                .then(resp => {
                    if (resp) setAssignees(e.value);
                })
        }

    }

    const getStatusTemplate = (option: IDropdownOption) => {
        if (!option) return <>{f({id: "choose"})}</>
        const status = internalTaskStatuses?.find(el => el.id === option.key);
        if (!status) return <></>
        return <div style={{
            width: "100%",
            padding: "0.3rem",
            textAlign: "center",
            color: "white",
            borderRadius: "1rem",
            //@ts-ignore
            background: statusColorMap[option.key],
            marginLeft: "0.5rem"
        }}>{option.label}</div>
    }

    const getUsernameTemplate = (employeeId: number) => {
        const employeeName = employees?.find(el => el.employeeId === employeeId)?.employeeName;
        return <Chip className={"p-m-1"} style={{padding: "0.25rem 0.75rem"}} label={employeeName} pt={{
            root: {style: {background: 'var(--primary-300)'}},
            label: {style: {color: "white"}}
        }}/>
    }

    const createInternalTask = () => {
        internalTaskService.createInternalTask({
            tittle: title,
            dueDateTime: dueDate!,
            description,
            assignedEmployeesIds: assignees
        })
            .then(resp => {
                if (resp.internalTaskId) setSelectedTaskId(resp.internalTaskId);
            }).catch()
    }

    const createCommentCb = () => {
        mutate(internalTaskKey);
    }

    return <>
        <div className={"grid w-full"}>
                <span className={"flex col-12"}>
                {showTitleEditor ?
                    <InputText value={title} onChange={e => setTitle(e.target.value)}
                               onBlur={() => setShowTitleEditor(false)}/> :
                    <>
                        <h2 style={{margin: 0}} onDoubleClick={() => {
                            if (!internalTask)
                                setShowTitleEditor(true)
                        }}>{title}</h2>
                        {!internalTask &&
                            <i className="pi pi-pencil" style={{marginLeft: "1rem", marginTop: "0.5rem"}}
                               onClick={() => setShowTitleEditor(true)}></i>
                        }
                    </>
                }

                </span>


            <div className={"col-12 grid w-full"}>
                <div className="col-12 md:col-7" style={{minHeight: "40vh"}}>
                    <Editor value={description} onTextChange={(e) => setDescription(e.htmlValue)}
                            headerTemplate={internalTask ? <></> : undefined}
                            style={{height: '320px'}} readOnly={selectedTaskId !== undefined}
                            placeholder={f({id: "description"})}/>
                </div>

                <div className="col-12 md:col-5" style={{minHeight: "40vh"}}>
                    <Fieldset legend={f({id: "details"})} style={{height: "100%", padding: 0}} pt={{
                        content: {style: {padding: "1rem 0"}}
                    }}>
                        <div className={"grid fluid"}>
                            {internalTask &&
                                <>
                                    <div className={"flex justify-content-between col-12"}>
                                        <label
                                            className={"col-6 font-bold flex align-items-center"}>{f({id: "status"})}:</label>
                                        <Dropdown className={"col-6"} itemTemplate={getStatusTemplate}
                                                  valueTemplate={getStatusTemplate}
                                                  placeholder={f({id: "choose"})}
                                                  options={statusOptions}
                                                  value={status}
                                                  optionValue={"value"} optionLabel={"label"}
                                                  onChange={handleStatusChange}
                                                  pt={{
                                                      root: {style: {border: "0px", boxShadow: "none"}},
                                                      input: {
                                                          style: {marginLeft: "0", paddingLeft: 0},
                                                          id: "status_dropdown_input"
                                                      }
                                                  }}
                                        />
                                    </div>

                                    <div className={"flex justify-content-between col-12"}>
                                        <label
                                            className={"col-6 font-bold flex align-items-center"}>{f({id: "createdBy"})}:</label>
                                        <div className={"col-6 flex flex-wrap align-items-center p-0"}>
                                            {getUsernameTemplate(createdBy || 0)}
                                        </div>
                                    </div>

                                    <div className={"flex justify-content-between col-12"}>
                                        <label className={"col-6 font-bold"}>{f({id: "createdOn"})}:</label>
                                        <label className={"col-6"}>{createdAt}</label>
                                    </div>

                                    <div className={"lex justify-content-between col-12"}>
                                        <label className={"col-6 font-bold"}>{f({id: "openedOn"})}:</label>
                                        <label className={"col-6"}>{openedOn}</label>
                                    </div>
                                </>
                            }

                            <div className={"flex justify-content-between col-12"}>
                                <label
                                    className={"col-6 font-bold flex align-items-center"}>{f({id: "taskDueDate"})}:</label>
                                <Calendar className={"col-6 font-bold p-0"} value={dueDate}
                                          disabled={!!internalTask}
                                          onChange={(e) => setDueDate(e.value)} pt={{
                                    input: {root: {style: {border: "0px", boxShadow: "none"}}}
                                }}/>
                            </div>

                            <div className={"flex justify-content-between col-12"}>
                                <label
                                    className={"col-6 font-bold flex align-items-start"}>{f({id: "assignedTo"})}:</label>
                                <div className={"col-6 flex flex-wrap align-items-center"}>
                                    {assignees?.map(getUsernameTemplate)}

                                    <OverlayPanel ref={op} showCloseIcon style={{width: "10vw"}}>
                                        <MultiSelect ref={multiselectRef} aria-expanded={true} className={"col-12"}
                                                     filter value={assignees} onChange={handleAssigneeChange}
                                                     options={userOptions} optionValue={"id"}
                                                     optionLabel={"description"}/>
                                    </OverlayPanel>

                                    <Button className={"ml-2"} icon={"pi pi-plus"} rounded outlined onClick={(e) => {
                                        op.current?.toggle(e)
                                        setTimeout(() => multiselectRef?.current.show(), 120);
                                    }}/>
                                </div>

                            </div>

                        </div>
                    </Fieldset>
                </div>
            </div>

            {!internalTask ?
                <div className={"col-12 flex justify-content-start"}>
                    <Button className={"col-2"} label={f({id: "create"})} onClick={createInternalTask}/>
                    <Button className={"col-2"} text label={f({id: "cancel"})} onClick={props.closeDialog}/>
                </div>

                :
                <div className={"col-12"}>
                    <InternalTaskComments comments={internalTask.replies || []}
                                          internalTaskId={internalTask.internalTaskId!}
                                          createCommentCb={createCommentCb}/>
                </div>
            }


        </div>

    </>
};

export default InternalTask
