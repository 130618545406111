import {InvoiceDetailsTemplate} from "../../../lib/types/types";

const ig : InvoiceDetailsTemplate = {
    providerName: "Ай Джи Инженеринг ООД",
    providerAddress: "София, жк. Връбница 1, бл. 505, вх. А",
    providerIdentityCode: "175188574",
    providerIdentityCodeZDDS: "BG175188574",
    providerMol: "Иван Ладов",
    providerBank: "Райфайзенбанк",
    providerBic: "RZBBBGSF",
    providerIban: "BG90RZBB91551000925738"
}

const fms : InvoiceDetailsTemplate = {
    providerName: "Еф Ем Ес Лимитид ЕООД",
    providerAddress: "Мездра, ул. Св.Патриарх Евтимий 31 вх.Б ет.3 ап.6",
    providerIdentityCode: "202526225",
    providerIdentityCodeZDDS: "BG202526225",
    providerMol: "Валентин Любомиров Любенов",
    providerBank: "Уникредит Булбанк АД",
    providerBic: "UBCRBGSF",
    providerIban: "BG04UNCR70001522692515"
}

const nipo : InvoiceDetailsTemplate = {
    providerName: "Нипо-Електроникс ООД",
    providerAddress: "София, ул. Родопски извор 50-52, ет.4 ап. 18",
    providerIdentityCode: "175165981",
    providerIdentityCodeZDDS: "BG175165981",
    providerMol: "Иван Ладов",
    providerBank: "Райфайзенбанк",
    providerBic: "RZBBBGSF",
    providerIban: "BG37RZBB91551052417202"
}

export const templates = {
    ig, nipo, fms
}
